import * as React from "react"
import mz from "../images/mz.png"
import badge from "../images/adobe-certified-expert-magento-commerce-developer.png"

const bgStyles = {
    backgroundColor: '#EAEEFC',
    color: '#414141'
}

// markup
const IndexPage = () => {
  return (
    <main className="antialiased">
        <div className="min-h-screen" style={bgStyles}>
            <div className="container mx-auto lg:pt-32 pb-8 px-8">
                <div className="flex lg:flex-nowrap flex-wrap items-center">
                    <div className="lg:w-1/2 w-100 order-1">
                        <h1 className="md:text-7xl text-4xl lg:mt-32 mt-8 font-bold leading-3">Michał Zymeła<br />
                            <span className="md:text-2xl text-xl font-medium leading-6 mt-6 block">Adobe Certified Expert - Magento Commerce Developer</span>
                        </h1>
                        <div className="lg:mt-24 mt-8 mb-12 md:text-xl">
                            <p>Hi, I’m certified developer based in Poland, <br />working with Magento since 2017.</p>
                        </div>
                        <div className="md:p-12 p-8 bg-white rounded-2xl inline-block md:pr-32">
                            <span className="block">Need help?</span>
                            <div className="font-bold md:text-xl tracking-wider">
                                <a href="mailto:contact@michalzymela.com">contact@michalzymela.com</a><br />
                                <a href="tel:+48508962193">+48 508 962 193</a>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2 w-full flex flex-wrap lg:flex-nowrap lg:order-2 justify-center">
                        <div className="flex-grow w-full xl:pl-24 px-12 lg:px-0 text-center flex justify-center">
                            <div className="w-auto max-w-xs lg:max-w-full relative">
                                <a href="https://www.youracclaim.com/badges/b780e512-a8bc-41d2-8a13-d324fe49104e/public_url"
                                   target="_blank"
                                   className="bg-white block p-6 rounded-full absolute mt-24 md:mt-36"
                                   style={{left:-60}}>
                                    <img src={badge} alt="Certification Badge" width="96" />
                                </a>
                                <img src={mz} alt="Michał Zymeła" />
                            </div>
                        </div>
                        <div className="flex lg:flex-col align-bottom lg:justify-end mt-8 lg:mt-0">
                            <a href="www.linkedin.com/in/michał-zymeła-60695078" target="_blank" style={{width: 40}} className="mb-4 mx-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#0e76a8" viewBox="0 0 3333 3333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"><path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm-215 1336h342v175h5c48-86 164-175 338-175 361 0 428 225 428 517v596h-357v-528c0-126-3-288-186-288-186 0-214 137-214 279v537h-357V1336zm-247-309c0 102-83 186-186 186-102 0-186-83-186-186 0-102 83-186 186-186 102 0 186 83 186 186zm-371 309h371v1113H834V1336z"/></svg></a>
                            <a href="https://github.com/mzymela" target="_blank" style={{width: 40}} className="mb-4 mx-4">
                                <svg enable-background="new 0 0 24 24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="m12 .5c-6.63 0-12 5.28-12 11.792 0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56 4.801-1.548 8.236-5.97 8.236-11.173 0-6.512-5.373-11.792-12-11.792z" fill="#959595"/></svg></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <title>Michał Zymeła - Magento Certified Developer</title>
    </main>
  )
}

export default IndexPage
